import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

// -- DISCORD ICON (Neuer Pfad) --
function DiscordIcon(props) {
  return (
    <svg
      viewBox="0 0 256 200"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={`w-5 h-5 mr-2 ${props.className || ""}`}
    >
      <path d="M216.856339,16.5966031 C200.285002,8.84328665 182.566144,3.2084988 164.041564,0 
      C161.766523,4.11318106 159.108624,9.64549908 157.276099,14.0464379 
      C137.583995,11.0849896 118.072967,11.0849896 98.7430163,14.0464379 
      C96.9108417,9.64549908 94.1925838,4.11318106 91.8971895,0 
      C73.3526068,3.2084988 55.6133949,8.86399117 39.0420583,16.6376612 
      C5.61752293,67.146514 -3.4433191,116.400813 1.08711069,164.955721 
      C23.2560196,181.510915 44.7403634,191.567697 65.8621325,198.148576 
      C71.0772151,190.971126 75.7283628,183.341335 79.7352139,175.300261 
      C72.104019,172.400575 64.7949724,168.822202 57.8887866,164.667963 
      C59.7209612,163.310589 61.5131304,161.891452 63.2445898,160.431257 
      C105.36741,180.133187 151.134928,180.133187 192.754523,160.431257 
      C194.506336,161.891452 196.298154,163.310589 198.110326,164.667963 
      C191.183787,168.842556 183.854737,172.420929 176.223542,175.320965 
      C180.230393,183.341335 184.861538,190.991831 190.096624,198.16893 
      C211.238746,191.588051 232.743023,181.531619 254.911949,164.955721 
      C260.227747,108.668201 245.831087,59.8662432 216.856339,16.5966031 Z 
      M85.4738752,135.09489 C72.8290281,135.09489 62.4592217,123.290155 62.4592217,108.914901 
      C62.4592217,94.5396472 72.607595,82.7145587 85.4738752,82.7145587 
      C98.3405064,82.7145587 108.709962,94.5189427 108.488529,108.914901 
      C108.508531,123.290155 98.3405064,135.09489 85.4738752,135.09489 Z 
      M170.525237,135.09489 C157.88039,135.09489 147.510584,123.290155 147.510584,108.914901 
      C147.510584,94.5396472 157.658606,82.7145587 170.525237,82.7145587 
      C183.391518,82.7145587 193.761324,94.5189427 193.539891,108.914901 
      C193.539891,123.290155 183.391518,135.09489 170.525237,135.09489 Z" />
    </svg>
  );
}

// -- GITHUB ICON --
function GitHubIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      aria-hidden="true"
      className={`w-5 h-5 mr-2 ${props.className || ""}`}
    >
      <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 
      3.438 9.8 8.207 11.387.6.113.793-.258.793-.577 
      0-.285-.01-1.04-.015-2.04-3.338.726-4.042-1.61-4.042-1.61
      -.546-1.387-1.333-1.757-1.333-1.757
      -1.091-.75.083-.735.083-.735 
      1.205.084 1.84 1.237 1.84 1.237 
      1.07 1.835 2.807 1.304 3.492.997 
      .107-.776.42-1.304.763-1.604
      -2.665-.3-5.466-1.334-5.466-5.93 
      0-1.31.469-2.382 1.236-3.22
      -.124-.302-.536-1.52.117-3.166 
      0 0 1.008-.322 3.302 1.23a11.52 11.52 0 0 1 3.003-.404 
      c1.02.005 2.04.138 3.003.404
      2.294-1.552 3.3-1.23 3.3-1.23 
      .655 1.645.243 2.863.12 3.166
      .77.838 1.234 1.91 1.234 3.22 
      0 4.61-2.805 5.625-5.477 5.92
      .43.369.81 1.096.81 2.21 
      0 1.596-.015 2.88-.015 3.27 
      0 .317.192.684.8.568
      C20.565 22.092 24 17.593 24 12.297 
      24 5.67 18.627.297 12 .297z" />
    </svg>
  );
}

// -- MAIL ICON --
function MailIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      aria-hidden="true"
      className={`w-5 h-5 mr-2 ${props.className || ""}`}
    >
      <path d="M20 4H4C2.897 4 2 4.897 2 6v12c0 
      1.103.897 2 2 2h16c1.103 0 2-.897 
      2-2V6c0-1.103-.897-2-2-2zm0 
      4-8 5-8-5V6l8 5 8-5v2z" />
    </svg>
  );
}

export default function Portfolio() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Prevent body scroll when mobile menu is open
  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [mobileMenuOpen]);

  // Container + Child Animation Variants
  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.15,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 15 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <div className="relative min-h-screen flex flex-col bg-gradient-to-tl from-gray-50 to-gray-100 overflow-x-hidden">
      {/* NAVBAR */}
      <nav className="fixed inset-x-0 top-0 z-30 border-b border-gray-200 bg-white/70 backdrop-blur-md">
        <div className="mx-auto flex h-16 max-w-screen-xl items-center justify-between px-4 md:px-8">
          {/* Logo / Brand */}
          <motion.div
            className="text-lg font-bold text-gray-900"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            Vontures
          </motion.div>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-6">
            <motion.a
              href="https://discord.com/users/1090614920862781440"
              className="whitespace-nowrap text-gray-700 hover:text-gray-900 transition-colors inline-flex items-center"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              <DiscordIcon />
              Discord
            </motion.a>
            <motion.a
              href="https://github.com/Vontures"
              className="whitespace-nowrap text-gray-700 hover:text-gray-900 transition-colors inline-flex items-center"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              <GitHubIcon />
              GitHub
            </motion.a>
            <motion.a
              href="mailto:0@vontures.com"
              className="whitespace-nowrap text-gray-700 hover:text-gray-900 transition-colors inline-flex items-center"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              <MailIcon />
              E-Mail
            </motion.a>
          </div>

          {/* Mobile Menu Button */}
          <button
            className="block md:hidden text-gray-700 hover:text-gray-900 transition-colors"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            aria-label="Open Menu"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
      </nav>

      {/* MOBILE MENU: Fullscreen Overlay */}
      <AnimatePresence>
        {mobileMenuOpen && (
          <motion.div
            key="mobile-menu"
            className="fixed inset-0 z-40 flex flex-col items-center justify-center bg-white"
            initial={{ y: "-100%" }}
            animate={{ y: 0 }}
            exit={{ y: "-100%" }}
            transition={{ duration: 0.4, ease: "easeOut" }}
          >
            <div className="absolute top-0 right-0 p-4">
              <button
                className="text-gray-700 hover:text-gray-900 transition-colors"
                onClick={() => setMobileMenuOpen(false)}
                aria-label="Close Menu"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            {/* Menu Links */}
            <div className="flex flex-col space-y-8 text-lg font-semibold text-gray-800">
              <a
                href="https://discord.com/users/1090614920862781440"
                className="flex items-center hover:text-blue-600 transition-colors"
                onClick={() => setMobileMenuOpen(false)}
              >
                <DiscordIcon className="mr-3 w-6 h-6" />
                Discord
              </a>
              <a
                href="https://github.com/Vontures"
                className="flex items-center hover:text-blue-600 transition-colors"
                onClick={() => setMobileMenuOpen(false)}
              >
                <GitHubIcon className="mr-3 w-6 h-6" />
                GitHub
              </a>
              <a
                href="mailto:0@vontures.com"
                className="flex items-center hover:text-blue-600 transition-colors"
                onClick={() => setMobileMenuOpen(false)}
              >
                <MailIcon className="mr-3 w-6 h-6" />
                E-Mail
              </a>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* CONTENT / HERO SECTION */}
      <main className="flex flex-1 flex-col items-center justify-center px-4 pt-20 text-center md:px-8">
        {/* Dekorative Hintergründe (kein Overflow) */}
        <motion.div
          className="pointer-events-none absolute top-[15%] left-[10%] h-64 w-64 
                     rounded-full bg-gradient-to-tr from-blue-100 to-blue-200 opacity-70 blur-2xl"
          initial={{ opacity: 0, scale: 0.7 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, ease: "easeOut" }}
        />
        <motion.div
          className="pointer-events-none absolute bottom-[15%] right-[10%] h-48 w-48 
                     rounded-full bg-gradient-to-tr from-pink-100 to-pink-200 opacity-70 blur-2xl"
          initial={{ opacity: 0, scale: 0.7 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, delay: 0.3, ease: "easeOut" }}
        />

        {/* Hero Text + Animation */}
        <motion.div
          className="relative z-10 flex max-w-xl flex-col"
          variants={containerVariants}
          initial="hidden"
          animate="show"
        >
          <motion.h1
            className="text-4xl font-extrabold text-gray-900 sm:text-5xl"
            variants={itemVariants}
          >
            Vontures
          </motion.h1>
          <motion.p
            className="mt-4 text-base sm:text-lg text-gray-600"
            variants={itemVariants}
          >
            Vontures, interested in web and software development, React, and C++.
            A minimal digital calling card – elegant and straightforward.
          </motion.p>
          <motion.div
            className="mt-8 flex flex-wrap items-center justify-center gap-4"
            variants={itemVariants}
          >
            <a
              href="https://discord.com/users/1090614920862781440"
              className="inline-flex items-center rounded-md bg-gray-900 px-8 py-3 text-base 
                         font-medium text-white shadow-md transition-colors hover:bg-gray-700 whitespace-nowrap"
            >
              <DiscordIcon className="mr-2 w-5 h-5" />
              Discord
            </a>
            <a
              href="https://github.com/Vontures"
              className="inline-flex items-center rounded-md bg-gray-900 px-8 py-3 text-base 
                         font-medium text-white shadow-md transition-colors hover:bg-gray-700 whitespace-nowrap"
            >
              <GitHubIcon className="mr-2 w-5 h-5" />
              GitHub
            </a>
            <a
              href="mailto:0@vontures.com"
              className="inline-flex items-center rounded-md bg-gray-900 px-8 py-3 text-base 
                         font-medium text-white shadow-md transition-colors hover:bg-gray-700 whitespace-nowrap"
            >
              <MailIcon className="mr-2 w-5 h-5" />
              E-Mail
            </a>
          </motion.div>
        </motion.div>
      </main>

      {/* FOOTER */}
      <footer className="z-20 border-t border-gray-200 bg-white/70 backdrop-blur-md">
        <div className="mx-auto flex h-14 max-w-screen-xl items-center justify-center px-4 md:px-8">
          <p className="text-xs text-gray-600 sm:text-sm">
            &copy; {new Date().getFullYear()} Vontures. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
}
