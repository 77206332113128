import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export default function Projects() {
  return (
    <section className="relative w-full min-h-screen flex flex-col items-center justify-center overflow-hidden bg-gradient-to-b from-white to-gray-50">
      
      {/* Decorative abstract background shapes */}
      <div className="absolute top-[-100px] left-[-100px] w-[400px] h-[400px]
                      bg-gradient-to-tr from-blue-50 to-blue-100 rounded-full 
                      filter blur-2xl opacity-70 z-0"></div>
      <div className="absolute bottom-[-100px] right-[-100px] w-[300px] h-[300px] 
                      bg-gradient-to-bl from-blue-50 to-blue-100 rounded-full 
                      filter blur-2xl opacity-60 z-0"></div>
      
      <div className="container mx-auto px-4 md:px-8 flex flex-col items-center text-center relative z-10 py-12">
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, ease: 'easeOut' }}
          className="max-w-5xl w-full"
        >
          <h1 className="text-3xl sm:text-4xl font-extrabold text-gray-800 tracking-tight leading-tight mb-4">
            Our Projects
          </h1>
          <p className="text-base text-gray-600 mb-6">
            Explore examples of our work.
          </p>
          
          {/* 3 columns on large screens */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {/* Card 1 */}
            <div className="bg-white rounded-md shadow hover:shadow-md transition-shadow p-3 text-left flex flex-col">
              <img 
                src="https://picsum.photos/400/300?random=1" 
                alt="Project Thumbnail" 
                className="w-full h-36 object-cover rounded-md mb-2"
              />
              <h2 className="text-base font-semibold text-gray-800 mb-1">Project Orion</h2>
              <p className="text-sm text-gray-600 mb-2">Bold Clear Impact</p>
              <a 
                href="#"
                className="inline-block px-3 py-2 bg-gray-900 text-white rounded-md hover:bg-gray-700 transition-colors text-center text-sm"
              >
                View
              </a>
            </div>

            {/* Card 2 */}
            <div className="bg-white rounded-md shadow hover:shadow-md transition-shadow p-3 text-left flex flex-col">
              <img 
                src="https://picsum.photos/400/300?random=2" 
                alt="Project Thumbnail" 
                className="w-full h-36 object-cover rounded-md mb-2"
              />
              <h2 className="text-base font-semibold text-gray-800 mb-1">Project Luna</h2>
              <p className="text-sm text-gray-600 mb-2">Smart Simple Real</p>
              <a 
                href="#"
                className="inline-block px-3 py-2 bg-gray-900 text-white rounded-md hover:bg-gray-700 transition-colors text-center text-sm"
              >
                View
              </a>
            </div>

            {/* Card 3 */}
            <div className="bg-white rounded-md shadow hover:shadow-md transition-shadow p-3 text-left flex flex-col">
              <img 
                src="https://picsum.photos/400/300?random=3" 
                alt="Project Thumbnail" 
                className="w-full h-36 object-cover rounded-md mb-2"
              />
              <h2 className="text-base font-semibold text-gray-800 mb-1">Project Sol</h2>
              <p className="text-sm text-gray-600 mb-2">Fresh Crisp Modern</p>
              <a 
                href="#"
                className="inline-block px-3 py-2 bg-gray-900 text-white rounded-md hover:bg-gray-700 transition-colors text-center text-sm"
              >
                View
              </a>
            </div>
          </div>

          {/* Back Button with a nicer style */}
          <div className="mt-8">
            <Link 
              to="/" 
              className="inline-flex items-center px-4 py-2 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 text-white rounded-md hover:from-gray-800 hover:via-gray-700 hover:to-gray-800 transition-colors font-medium text-sm"
            >
              ← Back to Home
            </Link>
          </div>
        </motion.div>
        
      </div>
    </section>
  );
}
